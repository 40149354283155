import React from "react";
import ISOService from "../sections/standardPage/ISO-OSHAService";
import { graphql } from 'gatsby'

const ISO_OSHA_Page = (data) =>
    <ISOService data={data} />

export default ISO_OSHA_Page;

export const query = graphql`
    {
        contentfulStandardPage(pageId: {eq: 4}) {
            heading
            subheading
            pageName
            pageId
            bodyText {
                json
            }
            twoColumns
            secondColumnBodyText {
                json
            }
            thirdColumnBodyText {
                json
            }
            fourthColumnBodyText {
                json
            }
            pageImage {
                fluid(maxWidth: 1180) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }`
